@main-header-height: 54px;

@green-color: #1890ff;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.playinfo-wrapper {
  padding: 10px;
  background-color: #e9e9e9;
  height: 100vh;
  overflow-y: auto;
  .im-btn-default {
    margin-top: 23px;
  }
  .send-info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    .send-action {
      margin-right: 15px;
    }
  }
  .play {
    color: green;
    font-size: 30px;
    cursor: pointer;
  }
  .singlePlay {
    padding-left: 5px;
    cursor: pointer;
  }
  .pause {
    color: red;
    font-size: 30px;
    cursor: pointer;
  }
  .quick-reply-item {
    border-bottom: 1px solid rgba(38, 38, 38, 0.2);
    margin: 0 !important;
    height: 48px;
    line-height: 48px;
  }
  .quick-reply-item-menu {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 500;
    position: relative;
  }
  .quick-reply-item-content {
    width: 100%;
    position: relative;
    padding-right: 17%;
  }
  .quick-reply-item-menu-op {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
  .quick-reply-item-li {
    border-bottom: 1px solid rgba(38, 38, 38, 0.17) !important;
    color: #000;
    font-size: 12px;
    padding: 15px;
    line-height: 17px;
  }
  .quick-reply-content {
    margin-left: 126px;
    background: #e9e9e9;
  }
  .list-style {
    overflow: auto;
    flex: 1;
    border: 1px solid rgba(38, 38, 38, 0.17);
  }
  .button-content {
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .scroll-box {
    height: 100vh;
  }
  .message-block {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    .role {
      display: block;
      text-align: center;
      height: 22px;
      width: 55px;
      background: #ff7b69;
      border-radius: 3px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin: 5px 12px 0 3px;
    }
    .role3 {
      background: rgb(125, 172, 102);
    }
    .role2 {
      background: #000;
    }
  }
  .list-wrapper {
    position: relative;
    .success-modal {
      background-color: #ffffff;
      opacity: 0.3;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@border-radius-base: 2px;