@main-header-height: 54px;

@green-color: #1890ff;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

html,
body,
#root {
  height: 100%;
}
::selection {
  background: #b2d7ff;
  // color: white;
}
body {
  font-family: PingFangSC-Regular, PingFangSC, Helvetica Neue, Helvetica, Hiragino Sans GB,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
}
.ant-layout-header {
  background: #eaf0f6;
}
.logo {
  height: 64px;
}

.im-body-font {
  font-size: 14px;
  color: #8c8c8c;
}

.im-body-font-small {
  font-size: 12px;
  color: #8c8c8c;
}

.im-body-font-large {
  font-size: 16px;
  color: #8c8c8c;
}

.im-title-font {
  font-size: 14px;
  color: #262626;
}

.im-title-font-small {
  font-size: 12px;
  color: #262626;
}

.im-title-font-large {
  font-size: 16px;
  color: #262626;
}

.im-caption-font {
  font-size: 14px;
  color: #bfbfbf;
}

.im-caption-font-small {
  font-size: 12px;
  color: #bfbfbf;
}

.im-caption-font-large {
  font-size: 16px;
  color: #bfbfbf;
}

.im-link-font {
  font-size: 14px;
}

.im-link-font-small {
  font-size: 12px;
}

.im-link-font-large {
  font-size: 16px;
}

.ant-btn {
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 24px;
  border: 1px solid rgba(191, 191, 191, 1);
  &.im-btn-icon {
    padding: 0 10px;
  }
}
.ant-btn-primary {
  color: #1890ff !important;
  background-color: transparent !important;
  border-color: #1890ff !important;
  text-shadow: none;
}
.ant-btn.ant-btn-sm {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 12px;
  &.im-btn-icon {
    padding: 0 10px;
  }
}
.ant-btn.ant-btn-lg {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  &.im-btn-icon {
    padding: 0 10px;
  }
}
.ant-tag {
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 12px;
  font-size: 12px;
}

.im-btn-default {
  background: #e9e9e9;
}
.im-btn-default:hover {
  background: #e9e9e9;
}
.im-btn-default:active {
  background: #e9e9e9;
}
.im-btn-default:focus {
  background: #e9e9e9;
}
.ant-btn-background-ghost {
  color: #8c8c8c;
}
.im-card {
  background: transparent;
}
.im-card .ant-card-body {
  padding: 20px 16px 10px;
  font-size: 13px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  font-size: 14px;
  font-weight: 600;
}
.ant-tabs-ink-bar {
  width: 56px;
  height: 3px;
}
.ant-tabs-bar {
  margin-bottom: 10px;
  border-bottom: none;
}
.ant-select {
  height: 30px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(191, 191, 191, 1);
}
.im-tag-default {
  border-radius: 4px;
  border: 1px solid rgba(191, 191, 191, 1);
  background: #e9e9e9;
}
.im-tag-default-closable {
  background: #d5d5d5;
  border-radius: 4px;
  color: #262626;
  font-size: 12px;
  border: none;
}
.im-tag-default-closable .anticon-close {
  color: #262626;
}
.ant-select {
  border: none;
  border-radius: 4px;
}
.ant-select-selection {
  box-sizing: border-box;
  background-color: none;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}
.ant-select-open .ant-select-selection {
}
.im-form {
  .ant-form-item-label {
    color: #8c8c8c;
    font-size: 12px;
    > label {
      color: #8c8c8c;
      font-size: 12px;
      &::after {
        content: '';
      }
    }
  }
  &.ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 1px;
  }
}
.im-input {
  color: #262626;
  height: 30px;
  font-size: 12px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(191, 191, 191, 1);
  padding: 7px 12px 8px;
  &.small {
    width: 70px;
  }
}
.im-input-search {
  border-radius: 4px;
  .ant-input-group {
    font-size: 12px;
    border-radius: 4px;
    .ant-input {
      font-size: 12px;
    }
    .ant-input-search-button {
      border-radius: 0px 4px 4px 0px;
      background: #e9e9e9 !important;
    }
  }
}
.im-bold {
  font-weight: 500;
}
.im-mgt15 {
  margin-top: 15px;
}
.ant-popover-buttons {
  .ant-btn.ant-btn-sm {
    height: 24px;
    border-radius: 2px;
    &.ant-btn-primary {
    }
  }
}
.panel-content .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: unset;
}
.panel-content .ant-collapse-borderless {
  background-color: #e9e9e9;
}
.panel-content-order {
  padding: 20px;
}
.panel-content
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: #8c8c8c;
}

.panel-content-order .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px;
  padding-left: 0;
}
.im-collapse-nopadding .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.im-collapse.ant-collapse-borderless {
  background-color: #e9e9e9;
}
.im-collapse-nopadding.ant-collapse-borderless {
  background-color: #e9e9e9;
}

.ant-collapse.im-collapse {
  background-color: #e9e9e9;
  .ant-collapse-item > .ant-collapse-header {
    width: 284px;
    height: 32px;
    line-height: 32px;
    background: rgba(38, 38, 38, 0.1);
    border-radius: 4px;
    padding: 0 12px;
    color: #262626;
    font-size: 12px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: #8c8c8c;
  }
}
.im-descriptions {
  .ant-descriptions-item {
    display: flex;
    padding-bottom: 12px;
  }
  .ant-descriptions-item-label {
    font-size: 12px;
    color: #8c8c8c;
    width: 70px;
  }
  .ant-descriptions-item-content {
    font-size: 12px;
    color: #262626;
    flex: 1;
  }
  .ant-descriptions-item-colon::after {
    content: '';
  }
}
.im-empty-status {
  margin-top: 143px;
  .ant-empty-image {
    height: auto;
    img {
      width: 200px;
      height: 150px;
    }
  }
  .ant-empty-description {
    position: relative;
    top: -12px;
    color: #8c8c8c;
    font-size: 12px;
  }
}
.ant-modal-footer {
  .ant-btn {
    background-color: #fff;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    font-size: 14px;
    &.ant-btn-primary {
      border: 1px solid #1890ff;
      color: #1890ff;
    }
  }
}

.ant-page-header-back {
  padding: 0 16px;
  margin-right: -8px;
}
.ant-page-header {
  background: #e9e9e9;
  min-height: 100vh;
  // padding: 0 16px;
  padding: 16px 0 0;
}
.ant-page-header-heading-title {
  color: #8c8c8c;
  font-size: 16px;
}

.group-item {
  .ant-list-item {
    padding-bottom: 4px;
  }
}
.see-img-modal {
  max-width: 720px;
  max-height: 560px;
}

.ant-pagination-item-active {
  background: transparent;
}

.ant-checkbox-inner {
  width: 14px;
  height: 14px;
}
.flex {
  display: flex;
}
.flex-space-between {
  justify-content: space-between;
}

.ant-table .ant-table-row-indent + .ant-table-row-spaced::after {
  width: 1px;
  height: 100%;
  margin: 32px 0 0;
  border-right: 1px solid #d9d9d9;
  content: ' ';
  visibility: visible;
}

@border-radius-base: 2px;