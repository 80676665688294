.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.scroller {
  overflow: auto;
}
.scroller::-webkit-scrollbar {
  display: block;
  width: 6px;
}
.scroller::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #c3c3c3;
}
.scroller::-webkit-scrollbar-track {
  background: transparent;
}
.ant-modal-content {
  border-radius: 8px;
}
.ant-modal-content .ant-modal-header {
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}
@font-face {
  font-family: 'iconfont';
  /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}
.lab {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  background: #e9e9e9;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.lab .session-line-1,
.lab .session-line-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  height: 50vh;
}
.lab .main-layout {
  position: relative;
  flex: 1;
  margin: 4px;
  box-shadow: 5px 2px 6px #f1e8e8;
}
.lab .main-layout .chat-window {
  position: absolute;
  top: 49px;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}
.lab .main-header,
.lab .message-box {
  font-size: 12px;
}
.lab .main-header .header-box {
  display: flex;
  align-items: center;
}
.lab .main-header .header-box > div {
  flex: 1;
}
.lab .main-header .ellipsis-name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #8c8c8c;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  height: 18px;
}
.lab .main-header .offline-box {
  display: block;
  position: relative;
}
.lab .main-header .offline-box::before {
  content: '离线';
  display: block;
  margin: auto;
  position: absolute;
  right: 0;
  z-index: 99999;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
}
.lab .session {
  padding: 0;
  height: 49px;
  align-items: center;
}
.lab .session .reported-box {
  position: relative;
}
.lab .session .reported-box::before {
  content: '违规';
  display: block;
  margin: auto;
  position: absolute;
  left: 0;
  z-index: 99999;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
}
.lab .session .client-img {
  margin-left: 2px;
}
.lab .session .target-img {
  margin-right: 3px;
  width: 32px;
}
.lab .message-scroll-wrapper .message-list {
  padding: 10px 8px 0;
}
.lab .message-scroll-wrapper .message-list .message-item {
  margin-bottom: 11px;
}
.lab .message-scroll-wrapper .message-list .message-item .message-text-wrapper {
  padding: 8px 10px;
  line-height: 1.3;
}
.lab .message-scroll-wrapper .message-list .message-item .message-text-wrapper .message-share .app {
  min-width: auto;
}
.lab .message-scroll-wrapper .message-list .message-item .message-avatar {
  margin-right: -6px !important;
  width: 30px;
  height: 30px;
}
.lab .message-scroll-wrapper .message-list .message-item .video-mask {
  width: 120px;
  height: 210px;
}
.lab .message-scroll-wrapper .message-list .message-item .date {
  padding: 1px 10px;
}
.btn-edit.ant-btn-icon-only.ant-btn-lg {
  position: fixed;
  bottom: 120px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
}
.btn-edit.ant-btn-icon-only.ant-btn-lg:hover {
  background: #fff;
}
.lab-empty {
  margin: 100px auto 0;
}
.lab-modal .ant-select-lg .ant-select-selection--multiple {
  font-size: 12px;
}
.lab-modal .ant-select {
  height: auto;
}
