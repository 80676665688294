@main-header-height: 54px;

@green-color: #1890ff;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.sider-tab {
  // height: calc(100vh - 28px);
  height: 100vh;
  color: rgb(120, 120, 120);
  .sub-tabs {
    display: flex;
    justify-content: space-between;
    padding: 0 18px;
  }
  .ant-tabs-tab {
    margin: 0 25px;
    // width: 60px;
    text-align: center;
    // padding: 8px 16px;
  }
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  .user-tab {
    color: rgb(120, 120, 120);
  }
  #scroll-box-1,
  #scroll-box-2 {
    height: calc(100vh - 44px);
  }
  .scroll-box {
    overflow-x: hidden;
    height: calc(100vh - 170px);
    .scroller();
    .ant-badge-count {
      font-size: 11px;
      height: 12px;
      line-height: 12px;
      border-radius: 12px;
      padding: 0 1px;
      margin-right: 0px;
    }
  }
  .sider-sessionfilter {
    .ant-select-selection {
      border: none;
    }
  }
  .ant-badge-count {
    font-size: 11px;
    height: 12px;
    line-height: 12px;
    border-radius: 12px;
    padding: 0 1px;
    margin-right: -12px;
  }
  .chat-topic {
    .scroll-box {
      height: calc(100vh - 114px);
    }
  }
  .warning-icon {
    padding: 0;
    position: absolute;
    left: -34px;
    top: -12px;
  }
}
.add-modal {
  .ant-modal-body {
    padding: 24px 24px 0;
  }
  .ant-modal-footer {
    padding: 10px 24px 24px;
  }
  .ant-btn.modal-search-btn {
    margin: 12px 0;
    width: 112px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    background: #fff;
  }
  .ant-btn.all-cancel-btn {
    margin: 12px 0;
    padding: 0;
    width: 64px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
  }
  .ant-input-group-wrapper.add-modal-search-input {
    margin-left: 8px;
    width: calc(100% - 92px);
    .ant-input {
      height: 24px;
      line-height: 24px;
      font-size: 12px;
    }
    .ant-btn {
      padding: 0;
      width: 56px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      background: #fff;
      border-radius: 0px 4px 4px 0px;
      border: 1px solid rgba(56, 158, 13, 1);
    }
  }
  .member-cancel.ant-btn.ant-btn-sm {
    padding: 0;
    width: 36px;
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
  }
}
.im-empty-status.no-data-container {
  margin-top: 53px;
}

@border-radius-base: 2px;