@main-header-height: 54px;

@green-color: #1890ff;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.main-header {
  position: relative;
  z-index: 128;
  text-align: left;
  .qun-title {
    line-height: 38px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.apply {
  padding: 16px;
  line-height: 24px;
  box-shadow: 0 0.5px rgba(38, 38, 38, 0.2);
  .apply-title {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }
  .apply-text {
    font-size: 12px;
    color: #8c8c8c;
  }
}
.session {
  height: 62px;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  // box-shadow: 0 1px rgba(38, 38, 38, 0.15);
  .target-img {
    width: 38px;
    margin-right: 8px;
    border-radius: 4px;
  }
  .client-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 8px;
  }
}
.account-item {
  color: #8c8c8c;
}
.account-list {
  padding: 0 !important;
  cursor: pointer;
  text-align: right;
}

.manager-box {
  display: inline-block;
  background-color: #ee2929;
  color: #fff;
  font-size: 12px;
  margin: 8px 0;
  margin-right: 6px;
  padding: 1px 4px;
}

.pub-box {
  display: inline-block;
  background-color: #188a22;
  color: #fff;
  border: 1px #000 solid;
  font-size: 12px;
  margin: 8px 0;
  margin-right: 6px;
  padding: 1px 4px;
}

.shills-box {
  display: inline-block;
  background-color: #f1f525;
  color: #000;
  border: 1px #000 solid;
  font-size: 12px;
  margin: 8px 0;
  margin-right: 6px;
  padding: 1px 4px;
}

.admin {
  width: 30px;
  height: 30px;
  float: right;
}

@border-radius-base: 2px;