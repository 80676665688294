@main-header-height: 54px;

@green-color: #1890ff;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.chat-pannel-header-callme-list {
  overflow-y: auto;
  max-height: 320px;
  min-width: 300px;
}
.at-me-style .ant-list-split .ant-list-item {
  border: unset;
}
.at-me-style .ant-list-lg .ant-list-item {
  padding: 8px 16px;
}

.at-me-overlay .ant-popover-inner-content {
  padding: 0;
}
.chat-pannel-header-callme-list-item {
  &:hover {
    cursor: pointer;
    transition: all 0.3s;
    background: rgba(56, 158, 13, 0.2);
    // box-shadow: 0 1px 1px 0 #389e0d;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  p {
    margin: 0 0 0 5px;
    width: 400px;
    font-size: 12px;
    line-height: 30px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    strong {
      margin-right: 5px;
    }
  }
}
.send-title {
  text-align: center;
  margin: 0;
}

// 发链接弹窗,预览图文样式
.share-card-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  .share-card {
    width: 300px;
    position: relative;
    border: 1px solid #ccc;
    .share-click {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 20px;
      color: #666;
      cursor: pointer;
    }
  }
}

@border-radius-base: 2px;