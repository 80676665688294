@main-header-height: 54px;

@green-color: #1890ff;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.user-card {
  box-sizing: border-box;
  position: relative;
  padding: 10px 10px 0px;
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .user-card-signature {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
  }
  .nickname-title {
    color: #262626;
    font-size: 20px;
    display: block;
  }
}
.user-card-baseinfo {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  padding-bottom: 20px;
  font-size: 14px;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(#787878, 0.2);
  .user-card-baseinfo-left {
    color: #8c8c8c;
  }
  strong {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  p {
    display: inline;
  }
  .user-card-baseinfo-avarar {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  .user-card-sex {
    font-size: 16px;
  }
  .user-card-sex-woman {
    color: red;
  }
  .user-card-sex-man {
    color: blue;
  }
}
.user-card-more {
  margin: 0 auto;
  padding-top: 20px;
  width: 100%;
  line-height: 18px;
  font-size: 14px;
  color: #8c8c8c;
  .field-value {
    color: #262626;
  }
  strong {
    font-weight: 400;
    text-align: left;
  }
}
.action {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 30px;
}
.iconfont {
  cursor: pointer;
}

@border-radius-base: 2px;